import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { motion } from "framer-motion"

const ComingSoon = () => {
  return (
    <motion.div
      animate={{ rotate: 360 }}
      transition={{ loop: Infinity, ease: "linear", duration: 10 }}
      sx={{
        position: `fixed`,
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
        right: [3, 4],
        bottom: 6,
        width: [3, 3, 4, 4],
        height: [3, 3, 4, 4],
        zIndex: 10,
        fontFamily: `secondaryItalic`,
        fontSize: 8,
        letterSpacing: 10,
        fill: `text`,
        transition: `fill 0.5s ease`,
        svg: {
          height: `auto`,
          maxWidth: `100%`,
          width: `100%`,
          "&:not(:root)": {
            overflow: `visible`,
          },
        },
      }}
    >
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 522 522"
      >
        <defs>
          <path
            d="M0,261a261,261 0 1,0 522,0a261,261 0 1,0 -522,0"
            id="txt-path"
          ></path>
        </defs>

        <motion.text>
          <textPath startOffset="0" xlinkHref="#txt-path">
            - COMING SOON - COMING SOON
          </textPath>
        </motion.text>
      </motion.svg>
    </motion.div>
  )
}

export default ComingSoon
