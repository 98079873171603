/** @jsx jsx */
import { string } from "prop-types"
import { Flex, Styled, jsx } from "theme-ui"
import { AnimatePresence, motion } from "framer-motion"
import { useSiteMetadata } from "helpers/hooks"

function hexToRGB(h, a = 1) {
  let r = 0,
    g = 0,
    b = 0

  // 3 digits
  if (h.length === 4) {
    r = "0x" + h[1] + h[1]
    g = "0x" + h[2] + h[2]
    b = "0x" + h[3] + h[3]

    // 6 digits
  } else if (h.length === 7) {
    r = "0x" + h[1] + h[2]
    g = "0x" + h[3] + h[4]
    b = "0x" + h[5] + h[6]
  }

  return "rgb(" + +r + "," + +g + "," + +b + "," + a + ")"
}

const About = ({ backgroundColor, isVisible }) => {
  const { behance, instagram, email, phone1, phone2 } = useSiteMetadata()
  const { a: A, p: P, ul: Ul } = Styled

  return (
    <AnimatePresence>
      {isVisible && (
        <Overlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          backgroundColor={backgroundColor}
        >
          <Content>
            <Flex
              sx={{
                flexDirection: `column`,
                justifyContent: [null, `space-around`, `space-between`],
              }}
            >
              <div sx={{ maxWidth: [null, null, 6, 7] }}>
                <P>
                  Somos un estudio de dise&ntilde;o especializado en la
                  creaci&oacute;n de conceptos creativos y visuales.
                </P>
                <P>
                  Creemos en la simplificidad, la funcionalidad, y la
                  est&eacute;tica como esencia de nuestro trabajo. Identidad de
                  marca, dise&ntilde;o gr&aacute;fico, web, editorial,
                  packaging, audiovisual, dise&ntilde;o de interiores...
                </P>
              </div>
              <div sx={{ maxWidth: [null, null, 6, 7] }}>
                <P>
                  Estamos ansiosos por desordenar el tablero y empezar nuevos
                  proyectos.
                </P>
                <A href={`mailto:${email}`}>{`${email}`}</A>
                <Flex>
                  <RowColumn>
                    <A href={`tel:${phone1}`}>{phone1}</A>
                  </RowColumn>
                  <RowColumn>
                    <A href={`tel:${phone2}`}>{phone2}</A>
                  </RowColumn>
                </Flex>
                <P>
                  Conoce m&aacute;s sobre nuestro trabajo o s&iacute;guenos.
                </P>
                <Flex>
                  <RowColumn>
                    <A rel="noreferrer" target="_blank" href={instagram}>
                      instagram
                    </A>
                  </RowColumn>
                  <RowColumn>
                    <A rel="noreferrer" target="_blank" href={behance}>
                      behance
                    </A>
                  </RowColumn>
                </Flex>
              </div>
            </Flex>
            <Flex
              sx={{
                my: [`auto`, `auto`, 0],
                flexDirection: `column`,
              }}
            >
              <P sx={{ maxWidth: [null, null, 6, 7] }}>
                Llevamos m&aacute;s de 10 a&ntilde;os trabajando en diversas
                agencias y estudios, conceptualizando y dise&ntilde;ando para
                diferentes marcas y clientes de moda, hosteler&iacute;a o
                institucionales:
              </P>
              <Flex sx={{ maxWidth: [null, null, 6, 7] }}>
                <RowColumn>
                  <Ul>
                    <li>Inditex</li>
                    <li>Zara</li>
                    <li>Zara Home</li>
                    <li>Pull & Bear</li>
                    <li>Sephora</li>
                    <li>Cabana das Bolboretas</li>
                    <li>Instyle</li>
                    <li>Estévez & Belloso</li>
                    <li>My Little Lab</li>
                    <li>Pernod Ricard</li>
                    <li>La Colmada</li>
                    <li>Mamá Chicó</li>
                    <li>Puchero Coffee Roasters</li>
                    <li>Rambleta</li>
                    <li>Seda</li>
                    <li>Enri Mür</li>
                    <li>Aldaba</li>
                  </Ul>
                </RowColumn>
                <RowColumn>
                  <Ul>
                    <li>Adelanta</li>
                    <li>Ayuntamiento Madrid</li>
                    <li>Ayuntamiento A Coruña</li>
                    <li>ClepIO</li>
                    <li>Seoga</li>
                    <li>Ferrovial</li>
                    <li>Frinsa</li>
                    <li>Genesal Energy</li>
                    <li>Jealsa</li>
                    <li>Megasa</li>
                    <li>Deston</li>
                    <li>AVA Centro Médico</li>
                    <li>Ópticas Sánchez Rubal</li>
                    <li>MIO Centro Odontológico</li>
                    <li>Alianza por la Solidaridad</li>
                    <li>IE Business School</li>
                    <li>GT Motiv</li>
                  </Ul>
                </RowColumn>
              </Flex>
              <Last>
                <P>&copy;bauz.studio</P>
              </Last>
            </Flex>
          </Content>
        </Overlay>
      )}
    </AnimatePresence>
  )
}

About.propTypes = {
  backgroundColor: string.isRequired,
}

export default About

const Overlay = ({ backgroundColor, ...rest }) => (
  <motion.div
    {...rest}
    sx={{
      backgroundColor: hexToRGB(backgroundColor, 0.9),
      position: `fixed`,
      zIndex: 5,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      px: [4, 5],
      py: 5,
      overflowY: `auto`,
      display: `flex`,
      flexDirection: `column`,
      justifyContent: [null, `equal-`, null],
    }}
  />
)

const Content = props => (
  <div
    {...props}
    sx={{
      position: `relative`,
      py: [null, null, 3],
      px: [null, null, 5],
      // flex: `1 0 auto`,
      flex: [null, 1],
      display: `flex`,
      // flexDirection: `column`,
      justifyContent: `space-between`,
      flexWrap: `wrap`,
      "> div": {
        maxWidth: [null, `66%`, `100%`],
      },
    }}
  />
)

const RowColumn = props => <div {...props} sx={{ flex: `0 1 50%` }} />

const Last = props => (
  <Flex
    {...props}
    sx={{
      position: [`static`, `absolute`, `static`],
      right: [null, 0, null],
      bottom: [null, 0, null],
      flex: 1,
      justifyContent: `flex-end`,
      alignItems: `flex-end`,
      mb: [5, 0],
      mt: [`auto`],
    }}
  />
)
