import React, { useState } from "react"
/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { func, node, string } from "prop-types"
import { Global } from "@emotion/core"
import { cssGlobal, mqHandheldLandscape } from "gatsby-plugin-theme-ui"
import { useSiteMetadata } from "helpers/hooks"
import About from "components/about"
import ComingSoon from "components/coming-soon"
import Logo from "assets/svgs/logo-bauz.svg"

const { a: A } = Styled

const Layout = ({ children, overlayBg, setIsSliding }) => {
  const { behance, instagram } = useSiteMetadata()
  const [showsAbout, setShowsAbout] = useState(false)

  function toggleAbout() {
    setIsSliding(showsAbout)
    setShowsAbout(!showsAbout)
  }

  function handleKeypress(e) {
    const { key } = e
    if (key === `Enter` || key === ` `) toggleAbout()
  }

  return (
    <>
      <Global styles={cssGlobal} />
      <Header>
        <Logo />
        <ToggleAbout
          onClick={toggleAbout}
          onKeyPress={handleKeypress}
          tabIndex="0"
          role="button"
          aria-pressed={showsAbout}
        >
          {showsAbout ? String.fromCharCode(8599) : `?`}
        </ToggleAbout>
      </Header>
      <Main>
        <About backgroundColor={overlayBg} isVisible={showsAbout} />
        <ComingSoon />
        {children}
      </Main>
      <HandheldLandscape>
        No somos mucho
        <br /> de estar tumbados
      </HandheldLandscape>
      <Footer>
        <A rel="noreferrer" target="_blank" href={instagram}>
          instagram
        </A>
        <A rel="noreferrer" target="_blank" href={behance}>
          behance
        </A>
      </Footer>
    </>
  )
}

Layout.defaultProps = {
  isSliding: () => null,
}

Layout.propTypes = {
  children: node.isRequired,
  overlayBg: string.isRequired,
  setIsSliding: func,
}

export default Layout

const Header = props => (
  <header
    {...props}
    sx={{
      [mqHandheldLandscape]: {
        display: `none`,
      },
      position: `fixed`,
      zIndex: 10,
      top: 0,
      left: 0,
      right: 0,
      height: 2,
      display: `flex`,
      justifyContent: `space-between`,
      alignItems: `center`,
      mx: [4, 5],
      "> svg": {
        variant: `text.logo`,
        height: `20px`, // needs tweaking
      },
    }}
  />
)

const HandheldLandscape = props => (
  <main
    {...props}
    sx={{
      display: `none`,
      [mqHandheldLandscape]: {
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
        fontSize: 6,
        position: `fixed`,
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
      },
    }}
  />
)

const Main = props => (
  <main
    {...props}
    sx={{
      [mqHandheldLandscape]: {
        display: `none`,
      },
    }}
  />
)

const ToggleAbout = props => (
  <div
    {...props}
    sx={{
      fontSize: [4, 6, 6, 7],
      pl: 4,
      py: 2,
      cursor: `pointer`,
      outline: `none`,
    }}
  />
)

const Footer = props => (
  <footer
    {...props}
    sx={{
      position: `fixed`,
      right: 0,
      bottom: 0,
      height: 2,
      width: [null, null, 6, 7],
      display: [`none`, `flex`],
      flexDirection: `column`,
      justifyContent: `center`,
      mr: [5, 6],
      maxWidth: [null, null, 6, 7],
      "> a": {
        m: 0,
      },
    }}
  />
)
