import { useRef, useEffect, useState } from "react"
// import ResizeObserver from "resize-observer-polyfill"
import { useStaticQuery, graphql } from "gatsby"

export function useSiteMetadata() {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          email
          phone1
          phone2
          instagram
          behance
        }
      }
    }
  `)

  return {
    email: site.siteMetadata.email,
    phone1: site.siteMetadata.phone1,
    phone2: site.siteMetadata.phone2,
    behance: site.siteMetadata.behance,
    instagram: site.siteMetadata.instagram,
  }
}

export function useMeasure() {
  const ref = useRef()
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 })
  const [ro] = useState(
    () =>
      new ResizeObserver(([entry]) => {
        const rect = entry.target.getBoundingClientRect().toJSON()

        const cleanedRect = Object.keys(rect).reduce((obj, key) => {
          obj[key] = +rect[key].toFixed(2)
          return obj
        }, {})

        set(cleanedRect)
      })
  )
  useEffect(() => {
    if (ref.current) ro.observe(ref.current)
    return () => ro.disconnect()
  }, [ro])
  return [{ ref }, bounds]
}

export function useWindowSize() {
  const isClient = typeof window === "object"
  const [windowSize, setWindowSize] = useState({
    width: isClient ? +window.innerWidth.toFixed(2) : 0,
    height: isClient ? +window.innerHeight.toFixed(2) : 0,
  })

  useEffect(() => {
    if (!isClient) return false

    function handleResize() {
      setWindowSize({
        width: +window.innerWidth.toFixed(2),
        height: +window.innerHeight.toFixed(2),
      })
    }

    handleResize()

    window.addEventListener(`resize`, handleResize)
    return () => window.removeEventListener(`resize`, handleResize)
  }, [isClient])
  return windowSize
}

export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    function listener(e) {
      // Do nothing if clicking ref's element or child elements
      if (!ref.current || ref.current.contains(e.target)) return
      handler(e)
    }

    document.addEventListener("mousedown", listener)
    document.addEventListener("touchstart", listener)

    return () => {
      document.removeEventListener("mousedown", listener)
      document.removeEventListener("touchstart", listener)
    }
  }, [ref, handler])
}

export function useAnimationFrame(func, throttle) {
  const isRunning = useRef(null)
  const isClient = typeof window === "object"

  if (!isClient) return false

  return function (...args) {
    if (isRunning.current && throttle) return

    isRunning.current = true
    window.requestAnimationFrame(() => {
      isRunning.current = false
      func.apply(this, args)
    })
  }
}

// Leading part not working
// export function useDebounce(value, delay) {
//   const [debouncedValue, setDebouncedValue] = useState(value)
//   const [previousValue, setPreviousValue] = useState(null)
//   const [leading, setLeading] = useState(true)
//
//   useEffect(() => {
//     const handler = setTimeout(() => {
//       setDebouncedValue(value)
//       setLeading(true)
//     }, delay)
//
//     return () => {
//       clearTimeout(handler)
//     }
//   }, [delay, value])
//
//   useEffect(() => {
//     console.log("leading: ", leading, " value: ", value)
//     if (leading) {
//       setDebouncedValue(value)
//       setLeading(false)
//     }
//   }, [leading, value])
//
//   return debouncedValue
// }
